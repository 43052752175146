document.addEventListener("DOMContentLoaded", function () {
    let contactForms = document.querySelectorAll(".contact-form");
    contactForms.forEach((form) => {
        let errorDisplay = form.querySelector(".error");
        let successContainer = form.querySelector(".success");
        let fileInput = form.querySelector("input[name=file]");
        let fileUploadButton = form.querySelector(".btn-file-upload");
        let formContainer = form.querySelector(".formContainer");
        let sendMsgButton = document.querySelector("#send-msg-btn");
        let contactButton = document.querySelector("#cta-btn-contact-page");


        fileUploadButton?.addEventListener("click", () => {
            fileInput.click();
        });

        fileInput?.addEventListener("change", () => {
            let file = fileInput.files[0];
            form.querySelector(".upload .file").textContent = file?.name ?? "No file chosen";
        });

        sendMsgButton?.addEventListener("click", () => {
            const formTitle = form.querySelector(".contact-title");

            const offset = 130; // Adjust this value to your preference
            const topPosition = formTitle.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
                top: topPosition,
                behavior: "smooth"
            });
        });
        contactButton?.addEventListener("click", () => {
            const formTitle = form.querySelector(".contact-title");

            const offset = 130; // Adjust this value to your preference
            const topPosition = formTitle.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
                top: topPosition,
                behavior: "smooth"
            });
        });



        form?.addEventListener("submit", event => {
            event.preventDefault();
            event.stopPropagation();

            let data = new FormData(form);
            let brandName = document.querySelector('#brand-name');
            let productName = document.querySelector('#product-name');
            let productCarton = document.querySelector('#product-carton');
            let productSize = document.querySelector('#product-size');
            let productFarmCode = document.querySelector('#product-farm-code');
            let productCode = document.querySelector('#product-code');
            let bestByDate = document.querySelector('#product-expiration-date');
            let packDateTime = document.querySelector('#product-pack-date');

            let brand = form.querySelector('#brand');
            let product = form.querySelector('#product');

            brandName && data.append('brand-name', brandName.value);
            productName && data.append('product-name', productName.value);
            productCarton && data.append('product-carton', productCarton.value);
            productSize && data.append('product-size', productSize.value);
            productFarmCode && data.append('product-farm-code', productFarmCode.value);
            productCode && data.append('product-code', productCode.value);
            bestByDate && data.append('best-by-date', bestByDate.value);
            packDateTime && data.append('pack-date-time', packDateTime.value);

            brand && data.append('brand-name', brand.value);
            product && data.append('product-name', product.value);
            
            form.classList.add("locked");
            errorDisplay.classList.add("hidden");

            fetch(form.action, {
                method: "POST",
                body: data
            })
            .then(response => response.json())
            .then((payload) => {
                if (payload.success) {
                    formContainer.style.display = "none";
                    successContainer.style.display = "block";
                }
                else {
                    form.classList.remove("locked");
                    errorDisplay.textContent = payload.message;
                    errorDisplay.classList.remove("hidden");
                }
            });

            return false;
        });
    });

    let faqSearch = document.getElementById("faq-search");
    let faqContainer = document.getElementById("search-results");
    faqSearch?.addEventListener("keyup", function (event) {
        let keywords = event.target.value;
        if(keywords.length > 3){
            if (event.keyCode === 13) {
                event.preventDefault();
                document.querySelector("#faq-search-btn").click();
            }
            fetch("/ajax/faq/" + keywords)
                .then(response => response.text())
                .then((html) => {
                    faqContainer.innerHTML = html;
                    // const accordionEl = faqContainer.querySelector('#faq-accordion');
                    // const accordion = new bootstrap.Collapse(accordionEl);
                });
        }
    });

    let stepHeader = document.querySelectorAll(".step-header");
    stepHeader.forEach((el) => {
        el.addEventListener("click", (event) => {
            let parent = el.closest(".help-step-container");
            parent.querySelectorAll(".help-step").forEach((step) => {step.classList.remove("active")});
            let step = event.target.closest(".help-step");
            step.classList.add("active");
            if (step.id === "step-brand") {
                document.getElementById('brand-tiles').classList.add("active");
            }
        });
    });

    let stepAskQuestion = document.querySelector(".link-step-ask-question");
    stepAskQuestion?.addEventListener("click", () => {
        document.querySelectorAll(".help-step").forEach((step) => {step.classList.remove("active")});
        document.querySelector("#step-ask-question").classList.add("active");
    });

    // Ask question view
    document.getElementById("btn-ask-question")?.addEventListener("click", (event)  => {
        document.getElementById("contact-grid").classList.remove("active");
        document.getElementById("ask-question-view").classList.add("active");
    });
    document.querySelectorAll(".link-show-contact-grid").forEach((el) => {
        el.addEventListener("click", (event) => {
            document.getElementById("contact-grid").classList.add("active");
            document.getElementById("ask-question-view").classList.remove("active");
            document.getElementById("share-concern-view").classList.remove("active");
            document.getElementById("help-view").classList.remove("active");
            document.getElementById("business-view").classList.remove("active");
        });
    });

    // Share Concern View
    document.getElementById("btn-share-concern")?.addEventListener("click", (event)  => {
        document.getElementById("contact-grid").classList.remove("active");
        document.getElementById("share-concern-view").classList.add("active");
    });

    document.querySelectorAll(".brand-tile").forEach((el) => {
        el.addEventListener("click", (event) => {
            document.querySelector('#brand-tiles').classList.remove("active");
            let brand = el.dataset.brand;
            let brandID = el.dataset.brandid;
            document.querySelectorAll('.product-tiles').forEach((el) => el.classList.remove("active"));
            document.querySelector(`#product-tiles-${brandID}`).classList.add("active");
            document.querySelector('#brand-step-label').innerHTML = brand;
            document.querySelector('#brand-name').value = brand;
            document.getElementById('step-brand').classList.remove("active");
            document.getElementById('step-product').classList.add("active");
        });
    });

    document.querySelectorAll(".product-tile").forEach((el) => {
        el.addEventListener("click", (event) => {
            let product = el.dataset.product;
            let brand = el.dataset.brand;
            if (brand == 'Happy Egg') {
                document.querySelector('.product-tiles').classList.remove("active");
                document.querySelector('.product-option').classList.add("active");
                document.querySelector('#product-step-label').innerHTML = product;
                document.querySelector('#product-name').value = product;    
            } else {
                document.getElementById('step-product').classList.remove("active");
                document.getElementById('step-product-code').classList.add("active");
                document.querySelector('#product-step-label').innerHTML = product;
                el.closest('.product-tiles').classList.remove("active");
                document.querySelector('#product-name').value = product;    
            }
        });
    });

    document.querySelectorAll('.btn-product-option').forEach((el) => {
        el.addEventListener("click", (event) => {
            el.parentElement.querySelectorAll('.btn-product-option').forEach((i) => {i.classList.remove("active")});
            el.classList.add("active");
            let option = el.dataset.option;
            document.querySelector(`#product-${option}`).value = el.dataset.value;
        });
    });

    document.querySelectorAll('.btn-enter-product-code').forEach((el) => {
        el.addEventListener("click", (event) => {
            document.querySelectorAll(".help-step").forEach((step) => {step.classList.remove("active")});
            document.querySelector("#step-product-code").classList.add("active");
        });
    });

    let validateProductcode = (input) => {
        var regex = new RegExp("^[a-z][0-9]{4}", "i");
        return regex.test(input);
    };

    let productCodeInput = document.querySelector("#product-code");
    productCodeInput?.addEventListener("keyup", (event) => {
        let productCode = productCodeInput.value;
        if(validateProductcode(productCode)){
            productCodeInput.closest('.product-code-wrap').classList.remove("invalid");
            productCodeInput.closest('.product-code-wrap').classList.add("valid");
        }else{
            productCodeInput.closest('.product-code-wrap').classList.remove("valid");
            productCodeInput.closest('.product-code-wrap').classList.add("invalid");
        }
    });

    document.querySelectorAll('.btn-share-concern').forEach((el) => {
        el.addEventListener("click", (event) => {
            document.querySelectorAll(".help-step").forEach((step) => {step.classList.remove("active")});
            document.querySelector("#step-concern-form").classList.add("active");

            const topPosition = document.querySelector("#step-product-code").getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: topPosition,
                behavior: "smooth"
            });
        });
    });

    // Help View
    document.getElementById("btn-need-help")?.addEventListener("click", (event)  => {
        document.getElementById("contact-grid").classList.remove("active");
        document.getElementById("help-view").classList.add("active");
    });

    // Business View
    document.getElementById("btn-business-inquiry")?.addEventListener("click", (event)  => {
        document.getElementById("contact-grid").classList.remove("active");
        document.getElementById("business-view").classList.add("active");
    });

});
